<template>
  <div>
    <v-alert type="warning" :value="true" text prominent v-if="division.missingPoints">
      Points are not fully loaded
    </v-alert>
    <v-btn color="color3" text @click.stop="manual = !manual">{{manual ? 'Hide ' : 'Show '}}Manual</v-btn>
    <v-data-table
      :items="editableTeams"
      :headers="headers"
      :loading="division.loading"
      loading-text="Loading... Please wait"
      :options.sync="page"
      class="elevation-1"
      hide-default-footer
      ref="sortableTable"
      id="sortableTable"
      :key="tKey"
      multi-sort
      :mobile-breakpoint="0"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" class="team" :key="item.id">
            <td width="46" style="max-width: 46px">
              <v-icon style="cursor: move" class="sortHandle" small>fas fa-bars</v-icon>
            </td>
            <td width="46" v-if="manual" style="max-width: 46px">
              <v-text-field
                label="Seed"
                single-line
                v-model="item.seed"
                type="number"
                class="pt-0 mt-0"
                color="color3"
                hide-details
              ></v-text-field>
            </td>
            <td width="46" style="max-width: 46px" v-else>{{item.seed}}</td>
            <td width="46" style="max-width: 46px">{{getOseed(item.oseed)}}</td>
            <td v-if="($vuetify.breakpoint.xs  && division.numOfPlayers === 2 && !division.useTeamNames) || tournament.showRatings.length > 0">
              <div class="noWrap" v-for="player in item.players" :key="player.id">{{player.name}}</div>
            </td>
            <td v-else>{{item.name}}</td>
            <td class="text-center" v-for="p in tournament.showRatings" :key="`rs-${p.id}`">
              <div v-if="false">{{item[`teamRatings${p.id}`]}}</div>
              <div class="noWrap" v-for="player in item.players" :key="player.id">{{player.getRating(p.id).currentRating}}</div>
            </td>
            <td class="text-center" v-for="p in tournament.pointSystems" :key="`ps-${p.id}`">
              {{item.teamPoints[p.id] | intelliFormat}}
            </td>
            <td
              v-for="column in columns"
              :key="column.value"
              :class="item.isDeleted ? 'deleted' : ''"
            >
              <span v-if="column.value === 'unix'">{{item[column.value] | unixToLocalDt}}</span>
              <span v-else>{{item[column.value] | intelliFormat}}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <!-- FABs -->
    <v-btn
      v-if="user && user.vbl"
      fab
      bottom
      right
      fixed
      style="margin-right: 120px"
      color="color3 color3Text--text"
      @click.stop="dialog = true"
      :loading="loading"
    >
      <v-icon>fas fa-sort-alt</v-icon>
    </v-btn>
    <v-btn
      v-if="dirty"
      fab
      bottom
      right
      fixed
      style="margin-right: 60px"
      color="color3 color3Text--text"
      @click.stop="saveSeeds"
      :loading="loading"
    >
      <v-icon>fas fa-save</v-icon>
    </v-btn>
    <v-btn
      fab
      bottom
      right
      fixed
      color="red white--text"
      @click.stop="cancel"
      :loading="loading"
    >
      <v-icon>fas fa-times</v-icon>
    </v-btn>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
      <v-toolbar color="primary">
        Manual Seed
      </v-toolbar>
        <v-card-text>
          <div v-for="i in editableTeams.length" :key="i">
            <v-autocomplete
              :label="`${i} Seed`"
              :items="editableTeams"
              v-model="et2[i-1]"
              item-id="id"
              item-text="name"
              return-object
            ></v-autocomplete>
          </div>
        </v-card-text>
      <v-card-actions>
        <v-btn color="success" @click.stop="saveEt2">save</v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import shuffle from 'shuffle-array'
import firstBy from 'thenby'
import { Sortable } from '@shopify/draggable'
import { mapGetters } from 'vuex'

export default {
  props: ['repField', 'columns'],
  mixins: [RouteMixin],
  data () {
    return {
      page: { itemsPerPage: -1 },
      loading: false,
      sortable: null,
      tKey: 0,
      editableTeams: [],
      et2: [],
      manual: false,
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['getDivision', 'getTeam', 'tournament', 'user']),
    division () {
      return this.getDivision(this.divisionId)
    },
    headers () {
      const h = [
        { sortable: false },
        { text: 'Seed', value: 'seed', align: 'left', sortable: true },
        { text: 'Prev Seed', value: 'oseed', align: 'left', sortable: true },
        { text: 'Name', value: 'name', align: 'left', sortable: true }
      ]
      this.tournament.showRatings.forEach(p => {
        h.push({ text: `${p.name} Ratings`, value: `teamRatings${p.id}`, align: 'center', sortable: true })
      })
      this.tournament.pointSystems.forEach(p => {
        h.push({ text: `${p.name} Points`, value: `teamPoints.${p.id}`, align: 'center', sortable: true })
      })
      this.columns.forEach(c => {
        h.push({ text: c.header, value: c.value, align: 'left', sortable: true })
      })
      this.manual && h.splice(1, { text: 'Seed', sortable: false })
      return h
    },
    dirtyTeams () {
      return this.editableTeams.filter(t => t.dirty)
    },
    a () {
      return this.division.missingSomePoints
    },
    dirty () {
      return !!this.editableTeams.filter(f => f.dirty).length || (this.division.pointsIssue && !this.division.missingSomePoints)
    },
    dto () {
      return this.dirtyTeams.map(t => {
        return {
          id: t.id,
          seed: t.seed,
          players: t.players
        }
      })
    }
  },
  methods: {
    getOseed (oseed) {
      return oseed < 999 ? oseed : ''
    },
    assignRandomSeeds () {
      shuffle(this.editableTeams)
      for (let i = 0; i < this.editableTeams.length; i++) {
        this.editableTeams[i].seed = i + 1
      }
      this.page.sortBy = ['seed']
      this.page.sortDesc = [false]
    },
    cancel () {
      this.$emit('cancel-click')
    },
    makeSortable () {
      const table = this.$refs.sortableTable.$el.getElementsByTagName('tbody')[0]
      if (!table) {
        console.log('no table')
        return
      }
      if (this.sortable) {
        this.sortable.destroy()
      }
      const sortable = new Sortable(table, {
        draggable: '.team',
        handle: '.sortHandle',
        mirror: {
          constrainDimensions: true
        }
      })

      sortable.on('sortable:start', () => {
        this.page.sortBy = []
      })
      sortable.on('sortable:stop', this.dragReorder)
      this.sortable = sortable
    },
    dragReorder ({ oldIndex, newIndex }) {
      const movedItem = this.editableTeams.splice(oldIndex, 1)[0]
      this.editableTeams.splice(newIndex, 0, movedItem)
      this.reseed(true)
    },
    reseed (sort) {
      let i = 1
      this.editableTeams.forEach(t => {
        t.seed = i++
      })
      this.tKey += 1
      this.et2 = this.editableTeams.map(m => m)
      this.$nextTick(() => {
        this.makeSortable()
      })
    },
    saveEt2 () {
      console.log('here')
      this.editableTeams.forEach(t => {
        const et = this.et2.findIndex(f => f.id === t.id)
        t.seed = et + 1
      })
      this.page.sortBy = ['seed']
      this.page.sortDesc = [false]
      this.dialog = false
    },
    saveSeeds () {
      this.loading = true
      this.$VBL.division.seedTeams(this.divisionId, this.dto)
        .then(() => {
          this.saveLocal(this.dto)
          this.$emit('seeding-saved')
        })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveLocal (dto) {
      this.dto.forEach(t => {
        const team = this.getTeam(t.id)
        team.seed = t.seed
      })
    },
    initialSort () {
      if (this.tournament.pointSystems.length) {
        const id = this.tournament.pointSystems[0].id
        this.editableTeams.sort(
          firstBy('finish')
            .thenBy('oseed')
            .thenBy(function (v1, v2) { return v1.teamPoints[id] - v2.teamPoints[id] }, -1)
            .thenBy('unix')
        )
        return
      }

      if (this.division.isUSAV || this.division.isAVP) {
        this.editableTeams.sort(
          firstBy('finish')
            .thenBy('oseed')
            .thenBy(function (a, b) { return b.usavPoints - a.usavPoints })
            .thenBy(function (a, b) { return b.aauPoints - a.aauPoints })
            .thenBy(function (a, b) { return b.p1440Points - a.p1440Points })
            .thenBy('unix')
        )
        return
      }
      if (this.division.isAAU) {
        this.editableTeams.sort(
          firstBy('finish')
            .thenBy('oseed')
            .thenBy(function (a, b) { return b.aauPoints - a.aauPoints })
            .thenBy(function (a, b) { return b.p1440Points - a.p1440Points })
            .thenBy(function (a, b) { return b.usavPoints - a.usavPoints })
            .thenBy('unix')
        )
        return
      }
      if (this.division.isBVNE) {
        return this.editableTeams.sort(
          firstBy('finish')
            .thenBy('oseed')
            .thenBy(function (a, b) { return b.bvnePoints - a.bvnePoints })
            .thenBy(function (a, b) { return b.aauPoints - a.aauPoints })
            .thenBy(function (a, b) { return b.p1440Points - a.p1440Points })
            .thenBy(function (a, b) { return b.usavPoints - a.usavPoints })
            .thenBy('unix')
        )
      }
      if (this.division.isP1440) {
        this.editableTeams.sort(
          firstBy('finish')
            .thenBy('oseed')
            .thenBy(function (a, b) { return b.p1440Points - a.p1440Points })
            .thenBy(function (a, b) { return b.aauPoints - a.aauPoints })
            .thenBy(function (a, b) { return b.bvnePoints - a.bvnePoints })
            .thenBy(function (a, b) { return b.usavPoints - a.usavPoints })
            .thenBy('unix')
        )
        return
      }
      if (this.division.isNcaa) {
        this.editableTeams.sort(
          firstBy('finish')
            .thenBy('oseed')
            .thenBy('movr', { direction: -1 })
            .thenBy('name', { direction: -1 })
            .thenBy('unix')
        )
        return
      }
      this.editableTeams.sort(
        firstBy('finish')
          .thenBy('oseed')
          .thenBy(function (a, b) { return b.usavPoints - a.usavPoints })
          .thenBy(function (a, b) { return b.aauPoints - a.aauPoints })
          .thenBy(function (a, b) { return b.p1440Points - a.p1440Points })
          .thenBy('unix')
      )
    },
    initTeams () {
      this.editableTeams = this.division.activeTeams.map(t => {
        const r = {
          id: t.id,
          seed: t.seed,
          oseed: t.seed || 999,
          name: t.name,
          cityState: t.cityState,
          club: t.club,
          highSchool: t.highSchool,
          aauPoints: t.aauPoints,
          avpaPoints: t.avpaPoints,
          avpaCPoints: t.avpaCPoints,
          p1440Points: t.p1440Points,
          bvnePoints: t.bvnePoints,
          usavPoints: t.usavPoints,
          movr: t.movr,
          teamPoints: t.teamPoints,
          players: t.players,
          field: t.field,
          pointsIssue: t.pointsIssue,
          missingSomePoints: t.missingSomePoints,
          get dirty () {
            return this.seed !== this.oseed || (this.pointsIssue && !this.missingSomePoints)
          },
          primaryState: t.primaryState,
          unix: t.unix
        }
        const x = t.ensureRatings(this.tournament.showRatings.map(m => m.id))
        for (const prop in x) {
          r[`teamRatings${prop}`] = x[prop]
        }
        return r
      })
      this.initialSort()
      this.reseed()
    },
    seedChange (payload) {
      // payload.forEach((p, i) => { p.seed = i + 1 })
    }
  },
  watch: {
    page: {
      handler: function (n, o) {
        if (n && o && n.sortBy && n.sortBy.length) {
          if ((JSON.stringify(n.sortDesc) !== JSON.stringify(o.sortDesc)) || (JSON.stringify(n.sortBy) !== JSON.stringify(o.sortBy))) {
            const sortKeysWithDirection = n.sortBy.map((m, i) => {
              return {
                prop: m,
                dir: n.sortDesc[i] ? -1 : 0
              }
            })
            let ordering = firstBy(k => 1)
            sortKeysWithDirection.forEach(o => {
              if (o.prop.includes('Points') || o.prop.includes('Ratings')) {
                ordering = ordering.thenBy((a, b) => parseFloat(a[o.prop]) - parseFloat(b[o.prop]), o.dir)
              } else {
                ordering = ordering.thenBy(o.prop, o.dir)
              }
            })
            this.editableTeams.sort(ordering)
            this.reseed()
          }
        }
      },
      deep: true
    }
  },
  mounted () {
    this.initTeams()
    this.makeSortable()
  }
}
</script>

<style scoped>
.shrink .v-input__slot {
  margin-bottom: 0 !important;
}
.draggable-mirror {
  background-color: #fff;
  border: 1px solid var(--v-color2-base);
}
.draggable--over {
  background-color: var(--v-color3-base) !important;
  color: var(--v-color3Text-base) !important;
}
.draggable--over .v-icon {
  display: none;
}
.bt {
  border-top: 1px solid rgba(0,0,0,0.12);
}
</style>
